import React from 'react';
import Map from '../components/Map';
import '../styles/MapPage.css';

const MapPage = ({ markers }) => {
    return (
        <div className="map-page">
            <h1>Mapa de localizaciones de yacimientos minerales españa</h1>
            <Map markers={markers} />
        </div>
    );
};

export default MapPage;
