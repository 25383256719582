import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import LandingPage from './pages/LandingPage';
import MapPage from './pages/MapPage';
import BlogPage from './pages/BlogPage';
import BlogPost from './components/BlogPost';
import Petrer from './asserts/Petrer.jpg'
import Tibi from './asserts/Tibi.jpg'
import Cofrentes from './asserts/Cofrentes.jpg'
import Orxeta from './asserts/Orxeta.jpeg'
import Finestrat from './asserts/Finestrat.jpeg'
import Mina from './asserts/Mina.png'
import Villamarchante from './asserts/villamarchante.jpg'
import Villarroya from './asserts/Villaroya.png'


import './styles/global.css';

const App = () => {

  const posts = [
    {
      id: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      title: 'Jacintos de compostela en la carretera de Orxeta-Finestrat',
      date: '2024-03-15',
      image: Finestrat,
      excerpt: 'Una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro',
      content: `
            A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela. Estos cristales, una variedad de cuarzo rojo que debe su coloración a la presencia de óxidos de hierro, son altamente apreciados tanto por su belleza como por su valor mineralógico.

Los Jacintos de Compostela se forman en rocas sedimentarias, principalmente en arenas y arcillas, donde los procesos de cementación y oxidación les confieren su característico tono rojizo, que puede variar desde un naranja intenso hasta un rojo oscuro. Estos cristales suelen tener un hábito prismático, con caras bien definidas y bordes afilados, lo que les otorga un gran atractivo visual.

La carretera de Orxeta-Finestrat es conocida entre coleccionistas y geólogos por la calidad excepcional de los Jacintos de Compostela que se encuentran en la zona. Los ejemplares extraídos aquí son valorados por su tamaño, brillo y color, haciéndolos muy deseados en el ámbito de la mineralogía.

Históricamente, estos cristales han sido recolectados no solo por su belleza, sino también por su supuesta capacidad de atraer la buena suerte, lo que les ha conferido un valor cultural además del científico. Aunque la extracción en esta área ha sido a pequeña escala, los Jacintos de Compostela de Orxeta-Finestrat han contribuido al conocimiento geológico regional, permitiendo estudiar los procesos sedimentarios y diagenéticos que dan lugar a la formación de estos cristales.
        `
    },
    {
      id: 'Argonitos en Loma Badá, Petrer',
      title: 'Argonitos en Loma Badá, Petrer',
      date: '2024-07-15',
      image: Petrer,
      excerpt: 'El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio',
      content: `
            Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito. El aragonito es una de las tres formas cristalinas en las que se puede encontrar el carbonato de calcio, siendo las otras dos la calcita y la vaterita. Este mineral se caracteriza por su estructura ortorrómbica y su hábito cristalino, que a menudo forma cristales aciculares o prismáticos.

En Loma Badá, el aragonito se presenta en una variedad de formas y colores, que van desde el blanco puro hasta tonos más oscuros, dependiendo de las impurezas presentes durante su formación. Los yacimientos en esta zona son de particular interés para los coleccionistas y geólogos debido a la calidad y el tamaño de los cristales, que pueden alcanzar dimensiones considerables y mostrar una transparencia notable.

La extracción del aragonito en Loma Badá ha sido objeto de estudio tanto por su valor mineralógico como por su importancia en la geología regional. Este mineral se forma en ambientes geológicos específicos, generalmente en condiciones de baja temperatura y alta presión, lo que lo convierte en un indicador clave de procesos geológicos en la región.
        `
    },
    {
      id: 'Azufre en la mina San Francisco, Tibi',
      title: 'Azufre en la mina San Francisco, Tibi',
      date: '2024-08-10',
      image: Tibi,
      excerpt: 'El azufre en su forma nativa, se caracteriza por su distintivo color amarillo y su fragilidad.',
      content: `
            La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre. Este mineral, fundamental en diversas industrias, se presenta en esta mina en su forma nativa, caracterizada por su distintivo color amarillo y su fragilidad.

El azufre de la mina San Francisco ha sido históricamente importante tanto para la economía local como para estudios geológicos. Su presencia en Tibi se debe a procesos geotérmicos y volcánicos que facilitaron la acumulación de azufre en esta zona, lo que convierte a la mina en un lugar de interés para geólogos y mineralogistas.

Los yacimientos de azufre en la mina San Francisco se distinguen por la pureza del mineral, que en algunos casos alcanza niveles elevados, haciendo que los cristales sean muy apreciados. Además, este azufre ha sido utilizado tradicionalmente en la producción de ácido sulfúrico, en la vulcanización del caucho, y en la fabricación de productos químicos.
        `
    },
    {
      id: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      title: 'Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat',
      date: '2024-04-05',
      image: Orxeta,
      excerpt: 'Calcita, un mineral de los más comunes en la corteza terrestre, destacan por su pureza y brillo',
      content: `
            El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita. Este mineral, uno de los más comunes en la corteza terrestre, se presenta en este lugar en forma de cristales bien formados que destacan por su pureza y brillo.

La calcita es un mineral de carbonato de calcio que se encuentra en una amplia variedad de formas y colores, dependiendo de las impurezas y las condiciones de formación. En el depósito de Keuper, la calcita aparece típicamente en tonos que van desde el blanco translúcido hasta el amarillo pálido, con cristales que pueden adoptar formas romboédricas o escalenoédricas, las cuales son características de este mineral.

El depósito de Keuper es parte de una formación geológica más amplia que se desarrolló durante el período Triásico, cuando se acumularon grandes cantidades de sedimentos en condiciones marinas y lacustres. La calcita en esta zona se originó principalmente a partir de la precipitación química de carbonato de calcio en ambientes evaporíticos, lo que ha dado lugar a los depósitos masivos que se observan hoy en día.

Este yacimiento es de especial interés tanto para geólogos como para coleccionistas, ya que ofrece una excelente oportunidad para estudiar los procesos sedimentarios y diagenéticos que ocurren en ambientes evaporíticos. Además, la calcita extraída de esta área es valorada por su uso en la industria, especialmente en la producción de cemento, cal y otros materiales de construcción.


        `
    },
    {
      id: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      title: 'Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes',
      date: '2024-8-5',
      image: Cofrentes,
      excerpt: 'Un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita',
      content: `
            El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita. Este volcán, uno de los pocos activos en la península ibérica en épocas geológicas pasadas, es una muestra destacada del vulcanismo en la región.

El olivino es un mineral que se encuentra comúnmente en rocas ígneas, especialmente en basaltos, y es reconocido por su color verde oliva y su estructura cristalina. En el Cerro de Agras, el olivino se presenta en forma de cristales bien formados, lo que lo convierte en un atractivo tanto para geólogos como para coleccionistas.

La iddingsita es un mineral de alteración que se forma a partir del olivino bajo condiciones de baja temperatura e hidratación, y es menos común que su mineral precursor. En Cofrentes, la iddingsita aparece como un mineral terroso de color marrón o rojizo, destacando en las zonas donde el olivino ha experimentado procesos de alteración.

Estos minerales son de particular interés porque ofrecen una visión clara de los procesos post-eruptivos que han afectado al Cerro de Agras. La transformación de olivino en iddingsita es un indicador de la interacción entre el magma y el entorno en el que se enfría, proporcionando información valiosa sobre la historia geológica del volcán.
        `
    },
    {
      id: "Azurita y Olivenita Monte Horquera, Villamarchante",
      title: "Azurita y Olivenita Monte Horquera, Villamarchante",
      date: "2024-9-14",
      image: Villamarchante,
      excerpt: "Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.",
      content: `
      El Monte Horquera, ubicado en Villamarchante, Valencia, es reconocido por sus yacimientos minerales, especialmente por la presencia de azurita y olivenita. Este sitio es una referencia geológica importante debido a la riqueza y diversidad de los minerales que se pueden encontrar en su entorno.

      La azurita, un mineral de cobre caracterizado por su distintivo color azul profundo, se forma en zonas de oxidación de los depósitos de cobre. En Monte Horquera, la azurita se presenta en cristales bien desarrollados y en masas nodulares, lo que la convierte en un atractivo para los coleccionistas y estudiosos de la mineralogía.

      La olivenita, otro mineral de cobre, es menos común pero igualmente apreciada. Se reconoce por su color verde oliva o marrón, y es un producto de la alteración hidrotermal de otros minerales de cobre. En Villamarchante, la olivenita aparece en asociaciones con azurita, lo que indica un ambiente de formación similar y un ciclo de alteración en el yacimiento.

      Estos minerales no solo tienen valor estético, sino que proporcionan información crucial sobre los procesos de oxidación y alteración hidrotermal que han tenido lugar en la región. El estudio de la azurita y la olivenita en Monte Horquera ofrece una ventana a la evolución geológica y mineralógica de este sector de la Comunidad Valenciana.
  `
    },
    {
      id: "Minerales Mina de San Vicente, Luchena",
      title: "Minerales Mina de San Vicente, Luchena",
      date: "2024-7-18",
      image: Mina,
      excerpt: "Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.",
      content: `
      La Mina de San Vicente, ubicada en Luchena, Murcia, es uno de los yacimientos históricos más importantes de la región, conocida por su riqueza en minerales como la galena, esfalerita y calcopirita. Este lugar ha sido una fuente significativa de extracción mineral desde la antigüedad.

      La galena, un sulfuro de plomo, es el mineral más abundante en la mina y destaca por su brillo metálico y su alta densidad. Se ha explotado durante siglos por su contenido de plomo y plata, y en la Mina de San Vicente se presenta en grandes masas cristalinas.

      La esfalerita, un sulfuro de zinc, se encuentra comúnmente asociada a la galena en este yacimiento. Reconocida por su color variable, que va del amarillo al marrón oscuro, la esfalerita es valorada no solo por su contenido en zinc, sino también por su belleza cristalina.

      La calcopirita, un mineral de cobre y hierro, completa el trío de minerales principales de la mina. Su color dorado metálico le ha valido el apodo de "oro de los tontos", y su presencia en Luchena es indicativa de la intensa actividad hidrotermal que originó el yacimiento.

      Estos minerales, además de ser valiosos para la minería, ofrecen una importante perspectiva sobre los procesos geológicos que dieron forma a la Mina de San Vicente. Su estudio continúa siendo clave para comprender la evolución minera de la región y sus características geológicas.
  `
    },
    {
      id: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      title: "Yacimiento Barbalimpia, Villarroya de los Pinares",
      date: "Cretácico",
      image: Villarroya,
      excerpt: "Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.",
      content: `
      El yacimiento fósil de Barbalimpia, ubicado en Villarroya de los Pinares, Teruel, es reconocido por su riqueza en fósiles del periodo Cretácico. Este sitio ha proporcionado una valiosa colección de fósiles de invertebrados marinos, incluyendo bivalvos, gasterópodos y amonites.

      Durante el Cretácico, esta región formaba parte de un ambiente marino, lo que explica la variedad de organismos que han sido preservados en el registro fósil. Los fósiles encontrados en Barbalimpia están excepcionalmente bien conservados, lo que permite a los paleontólogos estudiar la fauna marina de aquella época y comprender mejor la paleogeografía de la región.

      El yacimiento de Barbalimpia no solo es importante para la investigación científica, sino que también atrae a aficionados a la paleontología debido a su accesibilidad y a la calidad de los fósiles que se pueden encontrar.
  `
    }
  ];
  
  const markers = [
    { id: 1, position: [38.4526526, -0.7715992], description: 'Loma Badá, ubicada en la localidad de Petrer, en la provincia de Alicante, es conocida por sus yacimientos de minerales, en particular de aragonito.', image: Petrer, title: "Argonitos en Loma Badá, Petrer", moreInfoLink: "localizacion-yacimientos-mineral/Argonitos en Loma Badá, Petrer"},
    { id: 2, position: [38.5769537, -0.2589064], description: 'A lo largo de la carretera que conecta Orxeta y Finestrat, en la provincia de Alicante, se encuentran ricos yacimientos de Jacintos de Compostela.', image: Finestrat, title: "Jacintos de compostela en la carretera de Orxeta-Finestrat", moreInfoLink: "localizacion-yacimientos-mineral/Jacintos de compostela en la carretera de Orxeta-Finestrat" },
    { id: 3, position: [38.4960296126089, -0.5824822022584811], description: 'La mina San Francisco, ubicada en Tibi, Alicante, es un sitio de notable relevancia debido a sus ricos yacimientos de azufre.', image: Tibi, title: "Azufre en la mina San Francisco, Tibi", moreInfoLink: "localizacion-yacimientos-mineral/Azufre en la mina San Francisco, Tibi" },
    { id: 4, position: [38.5615094, -0.2186378], description: 'El depósito de Keuper, situado a lo largo de la carretera que conecta Orxeta y Finestrat en la provincia de Alicante, es conocido por sus yacimientos de calcita.', image: Orxeta, title: "Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat", moreInfoLink: "localizacion-yacimientos-mineral/Calcita en el deposito de Keuper, Carretera Orxeta-Finestrat" },
    { id: 5, position: [39.2391161, -1.0700520], description: ' El Cerro de Agras, situado en Cofrentes, Valencia, es un volcán de gran importancia geológica, conocido por sus yacimientos de olivino e iddingsita.', image: Cofrentes, title: "Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes", moreInfoLink: "localizacion-yacimientos-mineral/Olivino y Iddingsita Volcán Cerro de Agras, Cofrentes" },
    { id: 6, position: [39.5376810, -0.6282114], description: ' Un yacimiento mineral excepcional con abundancia de azurita y olivenita en Monte Horquera, Villamarchante.', image: Villamarchante, title: "Azurita y Olivenita Monte Horquera, Villamarchante", moreInfoLink: "localizacion-yacimientos-mineral/Azurita y Olivenita Monte Horquera, Villamarchante" },
    { id: 7, position: [40.10488720827497, -0.3024352921829018], description: ' Un yacimiento mineral histórico conocido por su diversidad, incluyendo galena, esfalerita y calcopirita en la Mina de San Vicente, Luchena.', image: Mina, title: "Minerales Mina de San Vicente, Luchena", moreInfoLink: "localizacion-yacimientos-mineral/Minerales Mina de San Vicente, Luchena" },
    { id: 8, position: [40.5004477, -0.6858980], description: ' Un importante yacimiento fósil en Villarroya de los Pinares, conocido por su diversidad de invertebrados marinos.', image: Villarroya, title: "Yacimiento Barbalimpia, Villarroya de los Pinares", moreInfoLink: "localizacion-yacimientos-mineral/Yacimiento Barbalimpia, Villarroya de los Pinares" }
  ];

  return (
    <Router basename="/">
      <Navbar />
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/inicio-exploracion-yacimientos-minerales" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage posts={posts} />} />
        <Route path="/blog-yacimientos-minerales" element={<BlogPage posts={posts} />} />
        <Route path="/localizacion-yacimientos-mineral/:id" element={<BlogPost posts={posts} />} />
        <Route path="/mapa-localizaciones-yacimientos-minerales" element={<MapPage markers={markers} />} />
      </Routes>
      <Footer />
    </Router>
  );
};

export default App;
